import * as ActiveStorage from "@rails/activestorage"
import * as Turbo from "@hotwired/turbo"
import TurboPower from "turbo_power"

import "arrive"
import "bootstrap"
import 'media-chrome'


ActiveStorage.start()

// stimulus support
import { Application } from "@hotwired/stimulus"
import { registerControllers } from "stimulus-vite-helpers"
const application = Application.start()
const controllers = import.meta.glob("~/**/*_controller.js", { eager: true })
registerControllers(application, controllers)

TurboPower.initialize(Turbo.StreamActions)

import AutoSubmit from "@stimulus-components/auto-submit"
application.register("auto-submit", AutoSubmit)

// stimulus controllers from vendors
// import CheckboxSelectAll from 'stimulus-checkbox-select-all'
// application.register('checkbox-select-all', CheckboxSelectAll)
// import NestedForm from "stimulus-rails-nested-form"
// application.register("nested-form", NestedForm)
// import ContentLoader from 'stimulus-content-loader'
// application.register('content-loader', ContentLoader)

// load ActionCable channels
// import '~/javascript/channels'

// mine
import "~/javascript/my_scripts"
