// hide flash notices after few seconds
document.arrive('.page-flash', { existing: true }, (el) => {
  setTimeout(() => {
    el.classList.add('animate__animated', 'animate__bounceOut')
    el.addEventListener('animationend', () => el.remove(), { once: true })
  }, 5000)
})


// scroll to anchor on load when needed, adjust scrollPadding on small viewports
document.addEventListener('turbo:load', function() {
  adjustScrollPadding()
  if (window.location.hash) {
    var el = document.querySelector(window.location.hash)
    if (el) { el.scrollIntoView(true) }
  }
})

// BS carousels
import { Carousel } from 'bootstrap'
document.arrive('.carousel', { existing: true }, el => {
  new Carousel(el, {})
})

// sharer.js
import 'sharer.js'
document.addEventListener('turbo:load', function(event) { Sharer.init() })

// plausible.io + Turbo
document.addEventListener('turbo:load', function() {
  try { plausible('pageview') }
  catch(err) { console.log('Plausible: not available.') }
})

// set proper scroll-margin on <html> to handle fixed header
function adjustScrollPadding() {
  const html = document.querySelector('html')
  const header = document.querySelector('header.cb-main-header')
  if (header) {
    if (window.screen.width < 992) {
      html.style.scrollPaddingTop = `${header.getBoundingClientRect().height}px`
    } else {
      html.style.scrollPaddingTop = '0px'
    }
  }
}

window.addEventListener('resize', adjustScrollPadding)

// make all external links open in new tab
document.arrive('.richtext', { existing: true }, function(el) {
  const selector = "a[href^='http']:not([href^='http://portal.cb.cz']):not([href^='https://portal.cb.cz']):not([href^='https://cbweb.demo.amagical.net']):not([href^='http://localhost'])"
  el.querySelectorAll(selector).forEach(e => e.setAttribute("target", "_blank"))
})
